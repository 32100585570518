/* eslint-disable no-new-wrappers */
import { decode } from 'html-entities';
import PropTypes from 'prop-types';

// Material-UI
import DeleteIcon from '@mui/icons-material/Delete';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Utils & Consts
import { RESOLUTION } from 'constants/image-resolution';
import { LIST_ITEM_FALLBACK } from 'constants/images-defaults';
import { splitPrice, transformImageUrl } from 'utils/functions';
import { getImageOrDefault } from 'utils/products';

/** Components */
import AddToCartPopoverWithVariation from 'components/Cart/AddToCartPopover/AddToCartPopoverWithVariations';
import AddToCartPopoverWithoutVariations from 'components/Cart/AddToCartPopover/AddToCartPopoverWithoutVariations';
import ConditionalWrap from 'components/ConditionalWrap';
import CustomImage from 'components/CustomImage';
import VirgoFlair from 'components/Flair';
import QuantitySelector from 'components/QuantitySelector';
import WishListButton from 'components/WishListButton';
import { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { priceStyles, normalCardStyles, smallCardStyles } from './ProductCardStyles';

const cardSize = {
  normal: {
    desktop: 236,
    mobile: 172,
  },
  small: {
    desktop: 172,
    mobile: 146,
  },
};

const productCardLayoutDefaultPropSx = {};

const ProductCardLayout = ({
  sx = productCardLayoutDefaultPropSx,
  product,
  index,
  popoverProduct = null,
  currencySymbol,
  conditionalWrapConfig,
  handleRedirectToRegister,
  selectedRRP,
  quantityToReset = null,
  selectedMinOrderValue,
  inCart,
  stockError,
  setStockError,
  inApiCall,
  inWishlist,
  hasVariations,
  isAddingToCart,
  isAuthenticated,
  isSelectingQuantity,
  showAddToCartPopover,
  productSubtotal,
  handleOnAddToCart,
  wholesalerSummary,
  handleAddToWishlist,
  handleRemoveFromCart,
  handleRedirectToBrand,
  quantitySelectorHelper,
  handleRemoveFromWishlist,
  handleAddToCartPopoverClose,
  handleOnAddToCartWithVariation,
  handleProductAlreadyAddedToCart,
  productCardVariant = 'normal',
  algoliaQueryID = '',
  productQuantityInCart = 0,
  selectedWSP = 0,
  selectedDiscountPrice = 0,
  isMobile,
}) => {
  const [addToCartButtonRef, setAddToCartButtonRef] = useState(null);
  const [addToCartIconHovered, setAddToCartIconHovered] = useState(false);

  const isCurrentPopoverProduct = product?.id === popoverProduct?.id && showAddToCartPopover;
  const { flairs } = product;

  const stockErrorMessage = stockError?.find(
    (error) => error?.productId === popoverProduct?.id && showAddToCartPopover
  );

  // Stock and pack data
  const minimumOrderQuantity = useMemo(
    () => Math.max(product?.productMinQuantity || 1, 1),
    [product?.productMinQuantity]
  );
  const maxStockAvailable = useMemo(
    () =>
      product?.productInventory?.productInventoryTracked === 'yes' ? product?.productInventory?.productStock || 0 : 0,
    [product?.productInventory?.productInventoryTracked, product?.productInventory?.productStock]
  );
  const productOrderQuantity = useMemo(
    () => Math.max(product?.productOrderQuantity || 1, 1),
    [product?.productOrderQuantity]
  );

  const trimCharThreshold = () => {
    return isMobile ? 26 : 42;
  };

  const commonStyles = {
    root: {
      web: {
        width: cardSize?.[productCardVariant]?.desktop,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      mobile: {
        width: cardSize?.[productCardVariant]?.mobile,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },
    title: {
      padding: 0,
      margin: 0,
      WebkitLineClamp: 2 /* number of lines to show */,
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    iconStyles: {
      web: {
        width: '18px',
        height: '18px',
      },
      mobile: {
        width: '18px',
        height: '18px',
      },
    },
    flair: {
      web: {
        fontSize: '10px',
        fontWeight: 700,
        lineHeight: '18px',
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: '1px 8px',
        margin: 0,
        height: '20px',
        boxSizing: 'border-box',
        borderRadius: '6px',
        maxWidth: 'calc(100% - 40px)',
        WebkitLineClamp: 1 /* number of lines to show */,
        overflow: 'hidden',
        display: 'block',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
      },
      mobile: {
        fontSize: '8px',
        fontWeight: 700,
        lineHeight: '14px',
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: '0.78px 6.22px',
        margin: 0,
        height: '16px',
        boxSizing: 'border-box',
        borderRadius: '4.67px',
        maxWidth: 'calc(100% - 30px)',
        WebkitLineClamp: 1 /* number of lines to show */,
        overflow: 'hidden',
        display: 'block',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
      },
    },
  };

  const screenType = isMobile ? 'mobile' : 'web';
  const isNormalCardVariant = productCardVariant === 'normal';
  const styles = isNormalCardVariant ? normalCardStyles : smallCardStyles;

  // product WSP
  let productPrice;
  const productPriceStyles = isMobile
    ? {
        priceTagClass: '',
        priceCent: {
          class: '',
          styles: {
            fontSize: '10px',
            alignSelf: 'center',
            textDecoration: 'line-through',
          },
        },
        splitPriceStyle: { fontSize: '8px' },
        boxDisplay: { display: 'flex' },
      }
    : {
        priceTagClass: 'priceTag',
        priceCent: { class: 'priceCent', styles: { fontSize: '11px' } },
        splitPriceStyle: {},
        boxDisplay: {},
      };

  if (hasVariations) {
    productPrice = (
      <Typography color="textPrimary" variant="body1" data-testid="wsp-typography" sx={styles.priceStyle[screenType]}>
        <span className={`${productPriceStyles.priceTagClass} notranslate`}>
          {currencySymbol}
          {splitPrice(selectedWSP).price}.{splitPrice(selectedWSP).floating}
        </span>
      </Typography>
    );
  } else if (selectedDiscountPrice) {
    productPrice = (
      <Typography
        color="error"
        variant="body1"
        data-testid="wsp-flair-typography"
        sx={styles.priceStyle[screenType]}
        component="div"
      >
        <span className="priceTag notranslate">
          {currencySymbol}
          {splitPrice(selectedDiscountPrice).price}.{splitPrice(selectedDiscountPrice).floating}
        </span>

        <Typography
          {...(splitPrice(selectedDiscountPrice).price?.length >= 2 ? {} : { component: 'span' })}
          color="textPrimary"
          variant="body2"
          data-testid="wsp-typography"
          sx={{ ...priceStyles.strike, ...productPriceStyles.priceCent.styles }}
        >
          <span className="priceTag notranslate">
            {currencySymbol}
            {splitPrice(selectedWSP).price}.{splitPrice(selectedWSP).floating}
          </span>
        </Typography>
      </Typography>
    );
  } else {
    productPrice = (
      <Typography color="textPrimary" variant="body1" data-testid="wsp-typography" sx={styles.priceStyle[screenType]}>
        <span className="priceTag notranslate">
          {currencySymbol}
          {splitPrice(selectedWSP).price}.{splitPrice(selectedWSP).floating}
        </span>
      </Typography>
    );
  }

  const setButtonRef = (node) => {
    if (node) {
      setAddToCartButtonRef(node);
    }
  };

  return (
    <Card
      sx={{
        ...commonStyles.root[screenType],
        ...sx,
      }}
      data-testid={`product-card-${hasVariations ? 'variant' : 'simple'}`}
    >
      <CardActionArea sx={{ position: 'relative' }} component="div">
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={styles.wrappers[screenType]}>
          {flairs?.length > 0 && (
            <VirgoFlair
              flair={flairs?.[0]}
              sx={{
                zIndex: 1,
                width: 'fit-content',
                ...commonStyles.flair[screenType],
              }}
            />
          )}
          <WishListButton
            {...{ inWishlist, handleRemoveFromWishlist, handleAddToWishlist }}
            sx={styles.wishlistButton[screenType]}
            iconSx={styles.wishlistIcon[screenType]}
          />
        </Stack>

        <ConditionalWrap {...conditionalWrapConfig}>
          <CustomImage
            width={isMobile ? 172 : 236}
            height={isMobile ? 172 : 236}
            sx={{
              objectFit: 'cover',
              maxHeight: `${isMobile ? '172px' : '236px'}`,
              minHeight: `${isMobile ? '172px' : '236px'}`,
            }}
            sizes="100vw"
            alt={product?.productName}
            priority={index < 4}
            fetchPriority={index < 4 ? 'high' : 'auto'}
            loading={index < 4 ? 'eager' : 'lazy'}
            fallbackSrc={LIST_ITEM_FALLBACK}
            src={
              product.productThumbnailUrl?.productThumbnailBaseUrl
                ? transformImageUrl(
                    product.productThumbnailUrl?.productThumbnailBaseUrl,
                    product.productThumbnailUrl?.productThumbnailName,
                    RESOLUTION.CARD.DESKTOP
                  )
                : getImageOrDefault({
                    base: product.productThumbnailUrl?.productThumbnailBaseUrl,
                    path: product.productThumbnailUrl?.productThumbnailName,
                  })
            }
            data-testid="product-card-image"
          />
        </ConditionalWrap>
      </CardActionArea>

      <Stack direction="column" sx={styles.infoWrapper[screenType]}>
        <ConditionalWrap {...conditionalWrapConfig}>
          <CardContent sx={commonStyles.title}>
            <Typography
              gutterBottom
              variant="h6"
              component="h3"
              color="textPrimary"
              data-testid="product-card-title"
              sx={styles.productTitle[screenType]}
            >
              {isNormalCardVariant
                ? decode(product.productName)
                : decode(product.productName?.substring(0, Math.min(product.productName?.length, trimCharThreshold())))}
            </Typography>
          </CardContent>
        </ConditionalWrap>

        {/* Seller / Wholesaler Info */}
        {isNormalCardVariant && product?.wholesaler && (
          <CardActionArea data-testid="product-card-brand-info" onClick={handleRedirectToBrand}>
            <Typography
              className="notranslate"
              sx={normalCardStyles.brandName[screenType]}
              data-testid="product-card-brand-name"
            >
              {product?.wholesaler?.name}
            </Typography>
            <Typography
              className="notranslate"
              sx={normalCardStyles.brandData[screenType]}
              data-testid="product-card-brand-data"
            >
              {`${product?.wholesaler?.country}, ${currencySymbol}${selectedMinOrderValue} min`}
            </Typography>
          </CardActionArea>
        )}

        {isAuthenticated ? (
          <>
            <CardContent sx={priceStyles.actions}>
              <Box>
                {productPrice}
                <Typography
                  variant="body2"
                  className="priceTag"
                  sx={styles.rrPrice[screenType]}
                  data-testid="rrp-typography"
                >
                  <span className="notranslate">
                    {`RRP ${currencySymbol}`}
                    {splitPrice(selectedRRP).price}.{splitPrice(selectedRRP).floating}
                  </span>
                </Typography>
              </Box>
              <Box key={`product-card-box-actions-${product?.id}-${productQuantityInCart}`} data-testid="card-actions">
                {hasVariations ? (
                  <IconButton
                    ref={setButtonRef}
                    key={`product-card-box-actions-icon-btn-variation-${product?.id}-${productQuantityInCart}`}
                    sx={
                      productQuantityInCart > 0 && !addToCartIconHovered
                        ? {
                            backgroundColor: '#212B36!important',
                            width: isMobile ? '30px' : '32px',
                            height: isMobile ? '30px' : '32px',
                            color: '#FFFFFF!important',
                            fontWeight: 700,
                            fontSize: isMobile ? '14px' : '19px',
                            textAlign: 'center',
                          }
                        : {
                            backgroundColor: addToCartIconHovered ? '#637381!important' : '#FFFFFF!important',
                            width: isMobile ? '30px' : '32px',
                            height: isMobile ? '30px' : '32px',
                            border: '1.5px solid',
                            borderColor: isAddingToCart || addToCartIconHovered ? '#637381' : '#000',
                          }
                    }
                    size="small"
                    color="primary"
                    onMouseEnter={() => {
                      if (!isMobile) {
                        setAddToCartIconHovered(true);
                      }
                    }}
                    onMouseLeave={() => {
                      if (!isMobile) {
                        setAddToCartIconHovered(false);
                      }
                    }}
                    onClick={handleOnAddToCartWithVariation}
                    disabled={isCurrentPopoverProduct}
                    data-testid="select-one-button"
                  >
                    {addToCartIconHovered && (
                      <AddRoundedIcon
                        key={`product-card-box-actions-icon-btn-variation-${product?.id}-${productQuantityInCart}-add-rounded-icon`}
                        sx={{
                          ...commonStyles.iconStyles[screenType],
                          color: '#FFF!important',
                        }}
                      />
                    )}

                    {!addToCartIconHovered &&
                      (productQuantityInCart > 0 ? (
                        <span
                          key={`product-card-box-actions-icon-btn-variation-${product?.id}-${productQuantityInCart}-quantity-in-cart`}
                          style={{
                            fontSize: '14px',
                            lineHeight: '18px',
                            fontWeight: 700,
                          }}
                        >
                          {productQuantityInCart > 99 ? '99+' : productQuantityInCart}{' '}
                        </span>
                      ) : (
                        <AddShoppingCartIcon
                          key={`product-card-box-actions-icon-btn-variation-${product?.id}-${productQuantityInCart}-add-shopping-cart-icon`}
                          sx={commonStyles.iconStyles[screenType]}
                        />
                      ))}
                  </IconButton>
                ) : (
                  <IconButton
                    key={`product-card-box-actions-icon-btn-simple-${product?.id}-${productQuantityInCart}`}
                    ref={setButtonRef}
                    sx={
                      productQuantityInCart > 0 && !isAddingToCart && !addToCartIconHovered
                        ? {
                            backgroundColor: '#212B36!important',
                            width: isMobile ? '30px' : '32px',
                            height: isMobile ? '30px' : '32px',
                            color: '#FFFFFF!important',
                            fontWeight: 700,
                            fontSize: isMobile ? '14px' : '19px',
                            textAlign: 'center',
                          }
                        : {
                            backgroundColor:
                              isAddingToCart || addToCartIconHovered ? '#637381!important' : '#FFFFFF!important',
                            border: '1.5px solid',
                            borderColor: isAddingToCart || addToCartIconHovered ? '#637381' : '#000',
                            width: isMobile ? '30px' : '32px',
                            height: isMobile ? '30px' : '32px',
                          }
                    }
                    size="small"
                    color="primary"
                    onMouseEnter={() => {
                      if (!isMobile) {
                        setAddToCartIconHovered(true);
                      }
                    }}
                    onMouseLeave={() => {
                      if (!isMobile) {
                        setAddToCartIconHovered(false);
                      }
                    }}
                    onClick={inCart ? handleProductAlreadyAddedToCart : handleOnAddToCart}
                    disabled={isCurrentPopoverProduct}
                    data-testid="add-to-cart-button"
                  >
                    {isAddingToCart && (
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress
                          size={commonStyles.iconStyles[screenType].width}
                          sx={{ color: '#FFF!important' }}
                        />
                      </Box>
                    )}

                    {!isAddingToCart && addToCartIconHovered && (
                      <AddRoundedIcon
                        sx={{
                          ...commonStyles.iconStyles[screenType],
                          color: '#FFF!important',
                        }}
                      />
                    )}

                    {!isAddingToCart &&
                      !addToCartIconHovered &&
                      (productQuantityInCart > 0 ? (
                        <span
                          key={`product-card-box-actions-icon-btn-simple-${product?.id}-${productQuantityInCart}-quantity-in-cart`}
                          style={{
                            fontSize: '14px',
                            lineHeight: '18px',
                            fontWeight: 700,
                          }}
                        >
                          {productQuantityInCart > 99 ? '99+' : productQuantityInCart}{' '}
                        </span>
                      ) : (
                        <AddShoppingCartIcon
                          key={`product-card-box-actions-icon-btn-simple-${product?.id}-${productQuantityInCart}-add-shopping-cart-icon`}
                          sx={commonStyles.iconStyles[screenType]}
                        />
                      ))}
                  </IconButton>
                )}
              </Box>
            </CardContent>

            {/* A2C Popover  */}
            {hasVariations
              ? isCurrentPopoverProduct && (
                  <AddToCartPopoverWithVariation
                    stockError={stockError}
                    setStockError={setStockError}
                    anchorEl={addToCartButtonRef}
                    data-testid="add-to-cart-popover__variations"
                    handleAddToCartPopoverClose={handleAddToCartPopoverClose}
                    open={showAddToCartPopover && isCurrentPopoverProduct}
                    handleRedirectToBrand={handleRedirectToBrand}
                    algoliaQueryID={algoliaQueryID}
                  />
                )
              : isCurrentPopoverProduct && (
                  <AddToCartPopoverWithoutVariations
                    showBackdropLoader={inApiCall}
                    brandSubtotal={wholesalerSummary()?.total}
                    data-testid="add-to-cart-popover"
                    productSubtotal={productSubtotal()}
                    anchorEl={addToCartButtonRef}
                    handleRedirectToBrand={handleRedirectToBrand}
                    handleAddToCartPopoverClose={handleAddToCartPopoverClose}
                    open={isCurrentPopoverProduct && productQuantityInCart > 0}
                    brandMinOrderValue={selectedMinOrderValue * 100} // convert back to cents
                  >
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <QuantitySelector
                        type="light"
                        sx={{
                          border: `1px solid #637381`,
                          borderRadius: '8px',
                        }}
                        initialValue={minimumOrderQuantity}
                        minimumValue={minimumOrderQuantity}
                        maximumValue={maxStockAvailable}
                        multipleOf={productOrderQuantity}
                        isDisabled={isSelectingQuantity}
                        quantityToReset={quantityToReset}
                        onChange={quantitySelectorHelper}
                        data-testid="quantity-selector"
                      />
                      {productOrderQuantity > 1 && (
                        <Typography variant="subtitle2" fontWeight="bold" data-testid="product-increment-message">
                          Sold in increments of {productOrderQuantity}
                        </Typography>
                      )}
                      <IconButton aria-label="delete cart item" onClick={handleRemoveFromCart}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>

                    {/* out of stock / stock quantity message */}
                    {stockErrorMessage && (
                      <Typography data-testid="stock-error-msg-A2CPopover" variant="body2" color="#d32f2f">
                        {stockErrorMessage?.message}
                      </Typography>
                    )}
                  </AddToCartPopoverWithoutVariations>
                )}
          </>
        ) : (
          <CardActions sx={{ width: '100%', margin: 0, padding: 0 }} data-testid="card-actions">
            <Button
              variant="contained"
              color="primary"
              onClick={handleRedirectToRegister}
              disabled={isAddingToCart}
              data-testid="register-button"
              sx={normalCardStyles.unauthenticatedButton[screenType]}
            >
              Register to see price
            </Button>
          </CardActions>
        )}
      </Stack>
    </Card>
  );
};

ProductCardLayout.propTypes = {
  sx: PropTypes.shape({}),
  productCardVariant: PropTypes.oneOf(['normal', 'small']),
  product: PropTypes.shape({
    id: PropTypes.string,
    WP_id: PropTypes.string,
    flairs: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        flairTitle: PropTypes.string,
        flairBackgroundColor: PropTypes.string,
        flairTextColor: PropTypes.string,
        data: PropTypes.shape({}),
      })
    ),
    productSlug: PropTypes.string.isRequired,
    brandSlug: PropTypes.string.isRequired,
    productMinQuantity: PropTypes.number,
    productPrice: PropTypes.arrayOf(
      PropTypes.shape({
        productRRP: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        productWSP: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        productCurrency: PropTypes.string,
      })
    ),
    productDiscount: PropTypes.arrayOf(
      PropTypes.shape({
        productDiscountValue: PropTypes.number,
        productDiscountCurrency: PropTypes.string,
      })
    ),
    productThumbnailUrl: PropTypes.shape({
      productThumbnailName: PropTypes.string,
      productThumbnailBaseUrl: PropTypes.string,
    }),
    productVariationLabels: PropTypes.arrayOf(PropTypes.string),
    productName: PropTypes.string.isRequired,
    wholesaler: PropTypes.shape({
      name: PropTypes.string,
      country: PropTypes.string,
      description: PropTypes.string,
      rating: PropTypes.string,
      id: PropTypes.string,
      wpId: PropTypes.string,
      logo: PropTypes.shape({
        wholesalerStoreLogoImage: PropTypes.string,
        wholesalerStoreLogoBasePath: PropTypes.string,
      }),
      minOrderValue: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreMinOrderAmount: PropTypes.number,
          wholesalerStoreMinOrderCurrency: PropTypes.string,
        })
      ),
      storeDiscount: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreDiscountPercentage: PropTypes.number,
          wholesalerStoreDiscountMinOrderAmount: PropTypes.number,
        })
      ),
    }),
    productBulkDiscount: PropTypes.shape({
      productBulkDiscountPercentage: PropTypes.number,
      productBulkDiscountMinOrderQuantity: PropTypes.number,
    }),
    productInventory: PropTypes.shape({
      productStock: PropTypes.number,
      productInventoryTracked: PropTypes.string,
    }),
    productOrderQuantity: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  popoverProduct: PropTypes.shape({ id: PropTypes.string }),
  conditionalWrapConfig: PropTypes.shape({
    condition: PropTypes.bool,
    getConditionalWrapper: PropTypes.func,
    getDefaultWrapper: PropTypes.func,
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  refProductUrl: PropTypes.string,
  productPrice: PropTypes.element.isRequired,
  unAuthenticatedButton: PropTypes.element.isRequired,
  selectedRRP: PropTypes.number.isRequired,
  quantityToReset: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  selectedMinOrderValue: PropTypes.number.isRequired,
  inCart: PropTypes.bool.isRequired,
  inApiCall: PropTypes.bool.isRequired,
  inWishlist: PropTypes.bool.isRequired,
  hasVariations: PropTypes.bool.isRequired,
  isAddingToCart: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isSelectingQuantity: PropTypes.bool.isRequired,
  showAddToCartPopover: PropTypes.bool.isRequired,
  productSubtotal: PropTypes.func.isRequired,
  handleOnAddToCart: PropTypes.func.isRequired,
  wholesalerSummary: PropTypes.func.isRequired,
  handleAddToWishlist: PropTypes.func.isRequired,
  handleRemoveFromCart: PropTypes.func.isRequired,
  stockError: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      message: PropTypes.string,
    })
  ).isRequired,
  setStockError: PropTypes.func.isRequired,
  handleRedirectToBrand: PropTypes.func.isRequired,
  quantitySelectorHelper: PropTypes.func.isRequired,
  handleRemoveFromWishlist: PropTypes.func.isRequired,
  handleAddToCartPopoverClose: PropTypes.func.isRequired,
  handleOnAddToCartWithVariation: PropTypes.func.isRequired,
  handleProductAlreadyAddedToCart: PropTypes.func.isRequired,
  algoliaQueryID: PropTypes.string,
  productQuantityInCart: PropTypes.number,
  handleRedirectToRegister: PropTypes.func.isRequired,
  selectedWSP: PropTypes.number,
  selectedDiscountPrice: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
};

export default ProductCardLayout;
